




































































































































import QueryParserMixin, {QueryType} from '~/mixins/query-parser-mixin'
import {humanFileSize, isEmpty, isEmptyString} from '~/utils/misc'
import {createRequest} from '~/utils/network-request'
import {CoursePermission} from '~/utils/permissions'
import copy from 'copy-to-clipboard'
import moment from 'moment'
import {mixins} from 'vue-class-component'
import {Component, Watch} from 'vue-property-decorator'
import PaginationMixin from '../../mixins/pagination-mixin'
import {OfflineClass} from '~/components/course/offline-course-model'

class Lesson {
    _id: string = ''
    course_id?: string
    class_id?: number
    lesson_id?: number
}

interface S3Object {
    Key: string,
    LastModified: string, // Date string
    ETag: string,
    Size: number,
    StorageClass: string

    lesson: Lesson
}

@Component({
    components: {},
    metaInfo() {
        return {
            title: 'Stream Archive'
        }
    }
})

export default class CourseSeriesList extends mixins(QueryParserMixin, PaginationMixin) {
    static permission = CoursePermission.StreamArchive

    keyword: string = ''
    step: number = 12
    queryDef = [
        {localVar: 'keyword', queryStr: 'search'},
        {localVar: 'currentPage', queryStr: 'page', type: QueryType.Number},
    ]

    rawTableData: S3Object[] = []
    filteredData: S3Object[] = []
    tableData: S3Object[] = []
    urlPrefix: string = ''

    selected = {}
    selectedAll = false

    editingKey: string = ''
    editingCourse?: string = ''
    editingClass?: number | '' = ''
    editingLesson?: number | '' = ''

    lmEditing: string
    showEditDialog = false
    classes: OfflineClass[] = []
    lessonList: { label: string, value: number }[] = []

    @Watch('editingClass')
    updateLessonList() {
        this.lessonList = this._updateLessonList()
        this.editingLesson = undefined
    }
    @Watch('editingCourse')
    checkUnsetCourse(val) {
        if(!val)
            this.editingClass = undefined
    }

    _updateLessonList() {
        if (!this.editingKey)
            return []
        if (!this.editingCourse || isEmptyString(this.editingCourse))
            return []
        const cl = this.classes.find(cl => cl.id === this.editingClass)
        return cl ? Array.from(cl.lessons.entries()).map(([i, lesson]) => {
            return {
                label: `Lesson ${i + 1} ${moment(lesson.start).format('M/D (ddd) HH:mm')}`,
                value: i
            }
        }) : []
    }

    get selectedLength() {
        return Object.values(this.selected).filter(s => s).length
    }

    get selectedKey() {
        return Object.keys(this.selected).filter(k => this.selected[k])
    }

    selectAllChanged(v) {
        for (const s of this.tableData) {
            if (v)
                this.$set(this.selected, s.Key, true)
            else
                this.$delete(this.selected, s.Key)
        }
    }

    switchSelected(evt, key: string) {
        if (evt.target.tagName.toLowerCase() !== 'td') {
            return
        }

        if (this.selected[key])
            this.$delete(this.selected, key)
        else
            this.$set(this.selected, key, true)
    }

    @Watch('selectedLength')
    selectedLengthChanged(v) {
        this.selectedAll = v === Math.min(this.step, this.tableData.length)
    }

    async created() {
        this.parseQuery()
        await this.updateArchives()
    }

    async updateArchives() {
        const res = await createRequest('/stream/archives', 'get').send()
        this.rawTableData = res.data.archives
        this.totalCount = res.data.count
        this.urlPrefix = res.data.url_prefix

        this.rawTableData.sort((a, b) => {
            return moment(b.LastModified).valueOf() - moment(a.LastModified).valueOf()
        })

        if (this.keyword) {
            this.filterKeyword()
        } else {
            this.tableData = this.rawTableData
            this.filteredData = this.tableData
        }

        this.toPage(this.currentPage)
    }

    @Watch('keyword')
    filterKeyword() {
        const k = this.keyword.toLowerCase()
        this.filteredData = this.rawTableData.filter(row => {
            return row.Key.replace('streams/', '').includes(k) ||
                row.lesson.course_id && row.lesson.course_id.toLowerCase().includes(k)
        })
        this.toPage(1)
    }

    async toPage(pageNumber: number) {
        this.currentPage = pageNumber
        this.setQuery()

        const start = (this.currentPage - 1) * this.step
        this.tableData = this.filteredData.slice(start, start + this.step)
    }

    async deleteSelected() {
        const s = this.selectedLength > 1 ? 's' : ''
        try {
            await this.$confirm(`Sure to delete ${this.selectedLength} video${s}?`)

            await createRequest('/stream/archives', 'delete', {}, {
                keys: this.selectedKey
            }).send()
            await this.updateArchives()
            this.selected = {}
        } catch {

        }
    }

    async download(key: string) {
        const fileLink = document.createElement('a')
        fileLink.href = await this.downloadLink(key)
        document.body.appendChild(fileLink)
        fileLink.click()
        document.body.removeChild(fileLink)
    }

    async downloadLink(key: string) {
        const res = await createRequest('/stream/archive/download-url', 'post', {}, {key}).send()
        return res.data.url
    }

    async copyLink(key: string) {
        const link = await this.downloadLink(key)
        copy(link, {
            debug: true,
            message: 'Press #{key} to copy',
        })
        this.$message({
            type: 'info',
            message: 'Link copied'
        })
    }

    lmString(lm: string) {
        return moment(lm).format('DD/MM/YYYY HH:mm')
    }

    fileSize(obj: S3Object) {
        return humanFileSize(obj.Size)
    }

    lessonString(obj: S3Object) {
        if (!obj.lesson || !obj.lesson.course_id)
            return '---'

        const cl = !isEmpty(obj.lesson.class_id) ? obj.lesson.class_id : '-'
        const les = !isEmpty(obj.lesson.lesson_id) ? obj.lesson.lesson_id! + 1 : '-'
        return `${obj.lesson.course_id} / ${cl} / ${les}`
    }

    rowStyle(obj: S3Object) {
        if (this.showEditDialog && this.editingKey === obj.Key)
            return `background-color: rgb(200,220,240)`

        const GB = 1000 * 1000 * 1000
        const size = Math.min(GB, obj.Size)

        let r = 220, g = 241, b = 200
        r = 255 - (255 - r) * size / GB
        g = 255 - (255 - g) * size / GB
        b = 255 - (255 - b) * size / GB
        return `background-color: rgb(${r},${g},${b})`
    }

    async editLesson(obj: S3Object) {
        this.editingKey = obj.Key
        this.editingCourse = obj.lesson.course_id
        this.editingClass = obj.lesson.class_id
        this.lmEditing = obj.LastModified
        this.showEditDialog = true

        await this.getClasses()
        this.updateLessonList()
        this.editingLesson = obj.lesson.lesson_id
    }

    async getClasses() {
        if (this.editingCourse) {
            try {
                const res = await createRequest(`/course/${this.editingCourse}/class/classes`, 'get').send()
                this.classes = res.data.classes
            } catch (e) {
                this.classes = []
            }
        }
    }

    async saveLesson() {
        this.showEditDialog = false
        const l: Lesson = {
            _id: this.editingKey,
            course_id: this.editingCourse || undefined,
            class_id: !isEmptyString(this.editingClass) ? this.editingClass as number : undefined,
            lesson_id: !isEmptyString(this.editingLesson) ? this.editingLesson as number : undefined,
        }
        await createRequest('/stream/archive/lesson', 'patch', {}, l).send()
        const row = this.tableData.find(row => row.Key === this.editingKey)
        row!.lesson = l
    }
}
